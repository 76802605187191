import React, { useState } from 'react';
import { generateClient } from 'aws-amplify/api';
import { Amplify } from 'aws-amplify';

import { createTextEntry, deleteTextEntry } from './graphql/mutations'; // Import delete mutation
import { getTextEntry } from './graphql/queries';
import awsExports from './aws-exports'; // Ensure this file exists and is correctly set up
import { customAlphabet } from 'nanoid'; // Using nanoid to generate short, unique IDs
Amplify.configure(awsExports);

const client = generateClient();

function App() {
  const [text, setText] = useState('');
  const [shortCodeLength, setShortCodeLength] = useState(4);
  const [shortCode, setShortCode] = useState('');
  const [createdShortCode, setCreatedShortCode] = useState('');
  const [retrievedText, setRetrievedText] = useState('');
  const [copied, setCopied] = useState(false);
  const [expirationTime, setExpirationTime] = useState(0); // Default to 0 minutes for immediate deletion
  const [showMoreOptions, setShowMoreOptions] = useState(false);

  const generateShortCode = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ', shortCodeLength);

  const handleSubmit = async () => {
    const id = generateShortCode();
    const expiration = expirationTime === 0 ? 0 : Math.floor(Date.now() / 1000) + expirationTime * 60; // Set expiration time
    const textEntry = { id, text, expiration };

    try {
      const result = await client.graphql({
        query: createTextEntry,
        variables: {
          input: textEntry,
        },
      });
      console.log('Created entry:', result.data.createTextEntry.id);
      setText('');
      setCreatedShortCode(result.data.createTextEntry.id);
    } catch (err) {
      console.error('Error creating entry:', err);
    }
  };

  const handleRetrieve = async () => {
    try {
      const result = await client.graphql({
        query: getTextEntry,
        variables: {
          id: shortCode,
        },
      });
      setRetrievedText(result.data.getTextEntry.text);

      if (expirationTime === 0) {
        await client.graphql({
          query: deleteTextEntry,
          variables: {
            input: { id: shortCode },
          },
        });
        console.log('Deleted entry:', shortCode);
      }
    } catch (err) {
      console.error('Error retrieving entry:', err);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(retrievedText).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset copied status after 2 seconds
    }).catch(err => {
      console.error('Error copying text:', err);
    });
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen flex-col">
      {createdShortCode &&
        <div className="w-full max-w-md p-8 bg-white shadow-md rounded-m mb-8">
          <h4 className="text-lg font-bold mb-4 text-center">Short code for the created entry:</h4>
          <h1 className="text-2xl font-bold mb-4 text-center">{createdShortCode}</h1>
        </div>
      }
      <div className="w-full max-w-md p-8 bg-white shadow-md rounded-m mb-8">
        <h1 className="text-2xl font-bold mb-4 text-center">Send a message</h1>
        <textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Enter your text here"
          className="w-full p-2 mb-4 border rounded-md"
        />
        <button
          onClick={handleSubmit}
          className="w-full py-2 mb-4 text-white bg-blue-500 rounded-md hover:bg-blue-600 gradient-button"
        >
          Submit
        </button>
        <p
          className="text-center text-blue-500 cursor-pointer"
          onClick={() => setShowMoreOptions(!showMoreOptions)}
        >
          {showMoreOptions ? 'Less options' : 'More options'}
        </p>
        {showMoreOptions && (
          <div className="mt-4">
            <div className="flex items-center mb-4">
              <label htmlFor="shortCodeLength" className="mr-2">Short code length: {shortCodeLength}</label>
              <input
                id="shortCodeLength"
                type="range"
                min="4"
                max="12"
                value={shortCodeLength}
                onChange={(e) => setShortCodeLength(Number(e.target.value))}
                className="w-full"
              />
            </div>
            <div className="relative flex items-center mb-4">
              <select
                value={expirationTime}
                onChange={(e) => setExpirationTime(Number(e.target.value))}
                className="w-full p-2 border rounded-md"
              >
                <option value="" disabled>Select expiration time</option>
                <option value={0}>0 minutes (delete immediately after reading)</option>
                <option value={5}>5 minutes</option>
                <option value={10}>10 minutes</option>
                <option value={30}>30 minutes</option>
                <option value={60}>60 minutes</option>
              </select>
              <div className="absolute right-0 mr-4">
                <span className="text-gray-500" title="If no expiration time is selected, the message will be deleted immediately after it is read.">ℹ️</span>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="w-full max-w-md p-8 bg-white shadow-md rounded-md">
        <h1 className="text-2xl font-bold mb-4 text-center">Get a message</h1>
        <input
          value={shortCode}
          onChange={(e) => setShortCode(e.target.value)}
          placeholder="Enter short code to retrieve text"
          className="w-full p-2 mb-4 border rounded-md"
        />
        <button
          onClick={handleRetrieve}
          className="w-full py-2 text-white bg-green-500 rounded-md hover:bg-green-600 gradient-button"
        >
          Retrieve
        </button>
        {retrievedText && (
          <div className="mt-4">
            <textarea
              value={retrievedText}
              readOnly
              className="w-full p-2 mb-4 border rounded-md"
              rows="5"
            />
            <button
              onClick={handleCopy}
              className="w-full py-2 text-white bg-yellow-500 rounded-md hover:bg-yellow-600"
            >
              Copy to Clipboard
            </button>
            {copied && <p className="mt-2 text-center text-green-500">Copied to clipboard!</p>}
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
